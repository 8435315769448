//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Illustration404 } from "@debionetwork/ui-icons";
export default {
  name: "NotFound",
  data: () => ({
    Illustration404
  }),
  methods: {
    handleToDashboard() {
      this.$router.push({
        name: "customer-dashboard"
      });
    }
  }
};